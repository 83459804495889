import React from 'react'
import { data } from '../../../constants';
import './Team.css';

const Team = () => {
  return (
    <div className="section-padding">
      <div className="row">
        {data.Team.map(({ name, position, info, photo }, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-12">
            <div className="card-team">
              <div className="card-team-header"> <img src={photo} alt={name} />
                <p> 
                <span>{name}</span>
                  {position}
                </p>
                {/* <a href={linkedin} alt={`Linkedin + ${name}`} ><BsLinkedin /></a> */}
              </div>
              <p>{info}</p>
              <br/>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Team