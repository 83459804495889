import React from 'react';
import './IconScroll.css';
import { images } from '../../constants';

const IconScroll = () => {
    return (
        <div className="partner-container">
            Saker du lär dig i vår kurs: 
            <div className="tscroll">
                <img src={images.o1Logo} alt="o1 logo image" title="o1 logo image" className="icon-scroll-image"/>
                <img src={images.o4} alt="4o logo image" title="4o logo image" className="icon-scroll-image"/>
                <img src={images.chatgpt} alt="ChatGPT logo image" title="ChatGPT logo image" className="icon-scroll-image"/>
                <img src={images.gptForWork} alt="GPT for Work logo image" title="GPT for Work logo image" className="icon-scroll-image"/>
                <img src={images.dalle} alt="DALL-E logo image" title="DALL-E logo image" className="icon-scroll-image"/>
            </div>
        </div>
    );
};

export default IconScroll;