import React from 'react';
import './IconScroll.css';
import Zapier from '../../assets/Zapier.svg';
import Midjourney from '../../assets/Midjourney.svg';
import Myflow from '../../assets/Myflow.svg';
import Make from '../../assets/Make.svg';
import OpenAI from '../../assets/OpenAI.svg';


const WorkWithLogos = () => {
    return (
        <div className="partner-container">
            Produkter vi arbetar med: 
            <div className="tscroll">
                <img src={Zapier} alt="Zapier logo" title="Zapier automatiseringar" />
                <img src={Midjourney} alt="Midjourney logo" title="Skapa bilder med Midjourney" />
                <img src={Myflow} alt="Myflow logo" title="Myflow automatiseringar" />
                <img src={Make} alt="Make logo" title="Make automatiseringar" />
                <img src={OpenAI} alt="OpenAI logo" title="Hjälp med OpenAI API" />
            </div>
        </div>
    );
};

export default WorkWithLogos;