import React from 'react';
import './Footer.css';
import { data } from '../../constants';
import { BsDiscord, BsLinkedin, BsYoutube } from 'react-icons/bs'

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const Footer = () => {
  return (
    <>
    <br/><br/><br/><br/><br/>
    <footer>
      <div className="row align-items-center">
        
        <div className="col-md-2 col-12">
          <span onClick={scrollToTop} className="">
            <h3>Techlära</h3>
          </span>

        </div>
        <div className="col-md-8 col-12 ">
          <ul className="navbar-footer">
            {data.Menu.slice(0, -1).map((item, index) => (
              <li key={index}> <a href={item.link} >{item.text}</a></li>
            ))}
          </ul>
        </div>
        <div className="col-md-2 col-12 text-center text-md-end fs-4">
          <a href="https://discord.gg/GaGQeE23vd" style={{padding: '10px'}} target="_blank" rel="noopener noreferrer">
            <BsDiscord style={{ color: 'white' }} />
          </a>
          <a href="https://www.linkedin.com/in/johannes-olsson-31a82965/" style={{padding: '10px'}} target="_blank" rel="noopener noreferrer">
            <BsLinkedin style={{ color: 'white' }} />
          </a>
          <a href="https://www.youtube.com/channel/UCXZthb2sGbrkS6aEX8EcEsg" style={{padding: '10px'}} target="_blank" rel="noopener noreferrer">
            <BsYoutube style={{ color: 'white' }} />
          </a>
        </div>
      </div>
      <div className="row">

        <div className="col-md-4 col-12">
          <ul className="info-contact">
            <li> <span>Kontakta oss:</span></li>
            <li>Email:  
            <a href="mailto:hej@techlara.se?subject=Hej"> hej@techlara.se</a>
            </li>
            <li>Telefon:  
            <a href="tel:0729449238"> 0729449238</a>
            </li>
            {/* <li>Phone: 555-567-8901</li> */}
            <li>Adress: 
              Magnus ladulåsgatan 1 <br></br>
              118 65 Stockholm</li>
          </ul>
        </div>

        <div className="rights">
          <p>2024 Techlära </p>
        </div>
      </div>
    </footer>
    </>
  )
}

export default Footer