import React, { useState, useEffect } from 'react';
import PopupComponent from '../Popup/PopupComponent';
import './Layout.css';

const Layout = ({ children }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [hasSeenNewsLetterPopup, setHasSeenNewsLetterPopup] = useState(
    localStorage.getItem('hasSeenNewsLetterPopup') === 'true'
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPopupOpen(true);
    }, 7000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {children}
      {isPopupOpen && !hasSeenNewsLetterPopup && (
        <PopupComponent 
          className='popup' 
          isOpen={isPopupOpen} 
          setIsOpen={setIsPopupOpen}
        />
      )}
    </>
  );
};

export default Layout;
