import React, { useState } from 'react';
import { Popup } from 'reactible-popups';
import { db } from "../../components/Firebase/firebase";
import { collection, addDoc, query, where, getDocs, updateDoc } from "firebase/firestore";
import './PopupComponent.css';

const PopupComponent = ({isOpen, setIsOpen}) => {
  localStorage.setItem('hasSeenNewsLetterPopup', 'true');
  const [emailError, setEmailError] = useState(null);
  const [guideIsHidden, setGuideIsHidden] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern

    // Check if the email is in a valid format
    if (!emailRegex.test(email)) {
      return "Ange en giltig emailadress.";
    }

    return null; // Return null if email is valid
  };

  const handleSubmit = () => {
    const email = document.querySelector('.email-input').value;
    const error = validateEmail(email);
    
    
    if (error) {
      setEmailError(error);
    } else {
      setEmailError(null);
      createFirestoreNewsletterUser(email);
      setGuideIsHidden(false);
    }
  };

  const createFirestoreNewsletterUser = async (email) => {
    try {      
      // Check if email already exists
      const q = query(
        collection(db, "newsletterEmails"), 
        where("email", "==", email)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Email exists, update timestamp
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, {
          timestamp: new Date(),
        });
      } else {
        // Create new document
        const docRef = await addDoc(collection(db, "newsletterEmails"), {
          email: email,
          timestamp: new Date(),
        });
      }
    } catch (error) {
      console.error("Error managing newsletter subscription: ", error);
    }
  };

  return (
    <div className="popup">
      <Popup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        position="center"
        overlayColor="rgba(255, 255, 255, 1)"
        overlayOpacity={1}
        closeOnOverlayClick={true}
        closeOnEscapeKey={true}
      >
        <div className="popup-content">
          <div className="popup-header">
            <h2>Välkommen till techlära!</h2>
            <button
              className="close-button"
              onClick={() => setIsOpen(false)}
            >
              X
            </button>
          </div>
          <p className="popup-text">Registrera dig till vårt nyhetsbrev för att få tips och nyheter om AI och ChatGPT. Registrera dig nu och få våran promptguide på svenska för att förbättra din ChatGPT-promptning</p>
          <br/><br/>
          <input
            type="email"
            placeholder="Ange din e-postadress"
            className="email-input"
            autocomplete="email"
          />
          {emailError && <p className="error-message">{emailError}</p>}
          {!guideIsHidden && 
            <div>
              <br/>
              <a 
                href="https://docs.google.com/document/d/15zTDkfklCKfL3IKXSvbTZMECdRfE-iX2GrXenTIOhoI/export?format=pdf" 
                className="popup-link" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                Klicka här för att ladda ner vår promptguide
              </a>
              <br/>
            </div>
          }
          <br/>
          {guideIsHidden && <button className="btn-green" onClick={handleSubmit}>Registrera dig</button>}
        </div>
      </Popup>
    </div>
  );
};

export default PopupComponent;
