import React from 'react'
import { data } from '../../constants'; 
import './Testimonials.css';
import { RoundedSubHeader } from '../../components/Headings/Headings';

const ConsultTestimonials = () => {
  return (
    <div className="section-padding" id="testimonials">
      <RoundedSubHeader title="Testamonials" text="Det här säger våra kunder" />
      <br/><br/>
      <div className="testimonials-container">
        {data.ConsultTestimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <p className="speech-bubble">{testimonial.testimonial}</p>
            <div className='testimonial-info'>
              <div className='testimonial-image'>
                <img src={testimonial.photo} alt={testimonial.name} />
              </div>
              <div className="testimonial-name">{testimonial.name}{testimonial.position}</div>
            </div>
          </div>  
        ))}
      </div>
      <br/>
    </div>
  )
}

export default ConsultTestimonials;