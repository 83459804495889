import React from "react";

const InternalHyperLinks = [
  {
    "text": "LLM",
    "translation": "[LLM](https://techlara.se/ainyheter/g0cSE4J3Jy)",
    "id": "g0cSE4J3Jy"
  },
  {
    "text": "o1",
    "translation": "[o1](https://techlara.se/ainyheter/y3H9x5Y2mN)",
    "id": "y3H9x5Y2mN"
  },
  {
    "text": "ChatGPT4o",
    "translation": "[ChatGPT4o](https://techlara.se/ainyheter/KUwPPKPdOG)",
    "id": "KUwPPKPdOG"
  },
  {
    "text": "Claude",
    "translation": "[Claude](https://techlara.se/ainyheter/xKpLmNqRds)",
    "id": "xKpLmNqRds"
  },
  {
    "text": "Gemini",
    "translation": "[Gemini](https://techlara.se/ainyheter/rgbI9RMVdF)",
    "id": "rgbI9RMVdF"
  },
  {
    "text": "AI-agenter",
    "translation": "[AI-agenter](https://techlara.se/ainyheter/AKfGaabrUb)",
    "id": "AKfGaabrUb"
  },
  {
    "text": "Custom GPT",
    "translation": "[Custom GPT](https://techlara.se/ainyheter/zMSm9aRGjk)",
    "id": "zMSm9aRGjk"
  },
  {
    "text": "Chatbot",
    "translation": "[Chatbot](https://techlara.se/chatbot)",
    "id": "chatbot"
  }
  
]

  const addHyperLinks = (text) => {
    InternalHyperLinks.forEach(link => {
      if (text.includes(link.text)) {
        text = text.replaceAll(link.text, link.translation);
      }
    });
    return text;
  }

  const addNewsHyperLinks = (text, currentNews) => {
    InternalHyperLinks.forEach(link => {
      if (link.id !== currentNews.id) {
        if (text.includes(link.text)) {
          text = text.replaceAll(link.text, link.translation);
        }
      }
    });
    return text;
  }

export { InternalHyperLinks, addHyperLinks, addNewsHyperLinks };