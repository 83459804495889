import React from "react";
import { RoundedSubHeader } from "../../../components/Headings/Headings";
import { data } from "../../../constants";
import "./Usp.css";
import team01 from "../../../assets/team01.png";
import { COURSE_ACTION } from '../../../constants/strings';
import Nav from 'react-bootstrap/Nav';
import { URL_REGISTER } from '../../../constants/strings';

const Usp = () => {

  const handleButtonClick = () => {
    window.location.href = URL_REGISTER;
  };

  return (
    <div className="section-padding">
      <RoundedSubHeader title="Heltäckande ChatGPT utbildning" text="Från grund till avancerad" />
      <br></br>
      <div className="row usp-component">
        {data.USP.map(({ name, header, info, photo }, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="usp-container">
              <div className="usp-team-header">
                <p>
                  <img src={photo} alt={name} title={name}/>
                </p>
                <h3>{header}</h3>
              </div>
              <p>{info}</p>
            </div>
          </div>
        ))}
        <div className="card-usp-container">
          <div className="card-usp-johannes">
            <div className="usp-team">
              <div className="usp-team-header">
                <p>
                  <img src={team01} alt="Team AI" className="team-image" />{" "}
                </p>{" "}
                <p>
                  {" "}
                  <span>Johannes Olsson</span>
                  Grundare & kursföreläsare{" "}
                </p>
                {/* <a href={linkedin} alt={`Linkedin + ${name}`} ><BsLinkedin /></a> */}
              </div>
              <p>Johannes har sedan ungdomen varit en techentusiast som byggt hemsidor, appar och alltid anammat den senaste tekniken.
               I 10 år arbetade han som iOS utvecklare men har nu specialiserat sig inom AI</p>
            </div>
          </div>
          <div className="card-usp-CTA">
            <h3>
              "Jag ser fram emot att utbilda dig inom ChatGPT och se resultat av
              hur mycket tid du kan spara."{" "}
            </h3>
            <br></br>
            <p>
              Har du frågor? Kontakta oss på:
              <br />
              <Nav.Link className="link" href="mailto:hej@techlara.se?subject=Hej">hej@techlara.se</Nav.Link> 
              
              </p>{" "}
            <br />
            <button className="btn-green" onClick={handleButtonClick}>{COURSE_ACTION}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usp;
