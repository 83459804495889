import React from 'react';
import { Helmet } from 'react-helmet';
import { RoundedSubHeader } from "../../components/Headings/Headings";
import ConsultHero from './ConsultHero';
import { Footer } from '../../container';
import { data } from '../../constants/';
import Contact from '../Contact/Contact';
import WorkWithLogos from '../../components/IconScroll/WorkWithLogos';
import ConsultTestimonials from '../../container/Testimonials/ConsultTestimonials';
import ReactMarkdown from 'react-markdown';
import { addHyperLinks } from '../News/InternalHyperLinks';

const Consult = () => {

  const keywords = [
    "AI-konsult",
    "AI-expert",
    "AI-rådgivning",
    "AI-implementering",
    "AI-strategi",
    "Maskininlärning",
    "Dataanalys",
    "Företagsautomatisering",
    "Digitalisering",
    "AI-lösningar",
    "Affärsutveckling med AI",
    "AI-utbildning",
    "Prediktiv analys",
    "Chatbots",
    "Naturlig språkbehandling",
    "Computer Vision",
    "AI-etik",
    "AI för företag",
    "Miljövänlig AI",
    "Skräddarsydda AI-tjänster",
    "AI-konsultering Sverige"
  ];

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "AI-konsulttjänster",
    "description": "Professionella AI-konsulttjänster för företag. Vi erbjuder expertis inom AI-implementering, strategiutveckling och skräddarsydda AI-lösningar.",
    "provider": {
      "@type": "Organization",
      "name": "techlära",
      "url": "https://techlara.se"
    },
    "serviceType": "AI-konsultering",
    "areaServed": "Sverige",
    "offers": {
      "@type": "Offer",
      "price": "Kontakta oss för prisuppgift",
      "priceCurrency": "SEK"
    },
    "keywords": keywords
  };

  const services = () => {
    return (
      <>
        {data.ServicesExtendedData.map(({ header, headerTagline, text, link, linkTitle }, index) => (
          <div className="service-container" key={index}>
            <RoundedSubHeader title={header} text={headerTagline} />
            <div >
              <ReactMarkdown>
                {addHyperLinks(text)}
              </ReactMarkdown>
              {link && 
              <div>
              <br/>
                <a href={link}>{linkTitle}</a>
              </div>
              }
            </div>
            <br/><br/>
          </div>
        ))}
      </>
    );
  }

  const handleButtonClick = () => {
    window.open("https://calendly.com/techlara/30min", "_blank");
  };

  return (
    <div className="consult-container">
      <Helmet>
        <title>AI konsult - Vi hjälper ert företag med AI - techlära.se </title>
        
        <link rel="canonical" href="https://techlara.se/aikonsult" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        
        <meta name="description" content="Professionella AI-konsulttjänster för företag. Vi erbjuder expertis inom AI-implementering, strategiutveckling och skräddarsydda AI-lösningar." />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="X-Robots-Tag" content="index, follow" />
        <meta name="keywords" content={keywords.join(', ')} />
        <meta name="publisher" content={"Johannes Olsson - Techlära"} />
      </Helmet>
      <ConsultHero />
      <br/><br/>
      <ConsultTestimonials />
      <br/>
      <WorkWithLogos />
      <br/><br/><br/>
      <div id="kontakt">
        
        <Contact />
      </div>
      
      <br/><br/>
      {services()}
      <br />
      <button className="btn-green" onClick={handleButtonClick}> Boka ett möte </button>
      
      <Footer />
    </div>
  );
};

export default Consult;